import api from "!../node_modules/.pnpm/style-loader@2.0.0_webpack@4.44.2/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../node_modules/.pnpm/css-loader@5.0.2_webpack@4.44.2/node_modules/css-loader/dist/cjs.js??ref--4-1!../node_modules/.pnpm/postcss-loader@4.2.0_postcss@8.2.6+webpack@4.44.2/node_modules/postcss-loader/dist/cjs.js??ref--4-2!../node_modules/.pnpm/sass-loader@10.1.1_node-sass@5.0.0+webpack@4.44.2/node_modules/sass-loader/dist/cjs.js??ref--4-3!./App.module.sass";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};